.confirmed__account {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--light-gray);

  min-height: 100vh;
}

.confirmed__account .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 400px;

  row-gap: 4rem;

  padding: 4rem 0.5rem;
}

.confirmed__account .container h1 {
  font-family: var(--font-bold);
  font-size: 2rem;
  text-align: center;
  color: #fff;
}

.confirmed__account .container .confirmed {
  background-color: var(--green);
  border-radius: 8px;

  border: none;
  outline: none;

  width: 100%;
  height: 90px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;

  padding: 0 1rem;
}

.confirmed__account .container .confirmed h5 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  color: #484848;
}

.confirmed__account .container a {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  text-decoration: none;
  column-gap: 28px;
  width: 100%;

  padding: 1rem;
}

.confirmed__account .container a:hover {
  background-color: var(--ultra-light-gray);
  border-radius: 8px;
}

.confirmed__account .container .loading {
  width: 48px;
  height: 48px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
