aside {
  min-height: 100vh;
  width: 275px;
  position: fixed;
  background-color: var(--middle-green);
  padding: 6.5rem 1rem;
  transition: 200ms all;
  z-index: 998;
}

aside.opened {
  margin-left: 0%;
}

aside.closed {
  margin-left: -275px;
}

aside .sidebar__header .user__info {
  padding: 0.5rem 0;
}

aside .sidebar__header .user__info h1 {
  font-family: 'IBM Plex Sans Bold';
  font-weight: 700;
  color: #fff;
  font-size: 1.125rem;
}

aside .sidebar__header .user__info h5 {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  color: #fff;
  font-size: 0.75rem;
}

aside .sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #383838;
}

aside .links {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

aside .links .list__group .link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

aside .links .list__group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

aside .links .list__group .link.selected {
  background-color: var(--dark-green);
}

aside .links .list__group .link .title {
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  border-radius: 4px;
}

aside .links .list__group .sublinks .title:hover {
  background-color: var(--middle-green);
}

aside .links .list__group .link img {
  transition: 0.5s all;
}

aside .links .list__group .link img.opened {
  transform: rotate(180deg);
}

aside .links .list__group .sublinks .title img,
aside .links .list__group .link .title img {
  width: 1.5rem;
}

aside .links .list__group .sublinks .title span,
aside .links .list__group .link .title span {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}

aside .links .list__group .sublinks {
  background-color: var(--dark-green);
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  display: none;
}

aside .links .list__group .sublinks.opened {
  display: block;
}

aside .links .list__group .sublinks .title {
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  padding: 0.75rem;
  border-radius: 4px;
}
