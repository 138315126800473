.table__row:hover {
  background-color: #575656;
}

.table__row td:nth-child(1) {
  border-left: none;
  border-right: none;
  cursor: pointer;
}

.table__row td {
  border-left: 1px solid #d8d8d8;
  padding: 10px 10px !important;
}

.table__row td span {
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
