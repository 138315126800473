header {
  width: 100%;
  position: fixed;
  height: 75px;
  background-color: var(--green);
  z-index: 999;
}

header .container {
  max-width: 1360px;
  height: 100%;

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 4rem;
}

header .container .left__side {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

header .container .left__side .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green);
  width: 36px;
  height: 36px;
  padding: 12px;
  border-radius: 4px;
}

header .container .left__side .menu img {
  width: 36px;
  height: 36px;
}

header .container .md-logo {
  width: 200px;
}

header .container .sm-logo {
  display: none;

  width: 60px;
}

header .container .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

header .container .buttons img {
  cursor: pointer;
  width: 40px;
}

header .container .buttons .sm-exit-button {
  display: none;

  margin-left: 2rem;
}

header .container .buttons button {
  width: 90px;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--dark-gray);
  border-radius: 4px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;

  margin-left: 32px;

  cursor: pointer;
}

@media (max-width: 865px) {
  header .container .sm-logo {
    display: block;
  }

  header .container .md-logo {
    display: none;
  }
}

@media (max-width: 560px) {
  header .container .buttons .md-exit-button {
    display: none;
  }

  header .container .buttons .sm-exit-button {
    display: block;
  }
}

@media (max-width: 560px) {
  header .container .buttons img {
    width: 40px;
  }
}

@media (max-width: 375px) {
  header .container {
    padding: 0 16px !important;
  }
}
