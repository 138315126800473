.device__details__modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  padding: 1rem;
}

.device__details__box {
  background-color: var(--dark-gray);
  padding: 2rem;
  width: 580px;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 20px;
}

.device__details__box .header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.device__details__box .header img {
  cursor: pointer;
}

.device__details__box .body {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  margin-bottom: 3rem;
}

.device__details__box .body .info {
  display: flex;
  align-items: center;
}

.device__details__box .body .info img {
  margin-right: 21px;
}

.device__details__box .body .info h4 {
  font-family: var(--font-regular);
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

.device__details__box .body .info h5 {
  font-family: var(--font-regular);
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
}

.device__details__box .buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 1rem;
}

.device__details__box .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 65px;
  width: 260px;
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;
  cursor: pointer;
}

.device__details__box .buttons .confirm {
  background-color: var(--green);
}

.device__details__box .buttons .cancel {
  background-color: var(--red);
}

.device__details__box .buttons .disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  pointer-events: none;
}

@media (max-width: 375px) {
  .device__details__box .body .info h4 {
    font-size: 1.5rem;
  }

  .device__details__box .body .info h5 {
    font-size: 1rem;
  }
}
