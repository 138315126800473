.signup {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--light-gray);

  min-height: 100vh;

  padding: 2rem 1rem;
}

.signup form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  row-gap: 15px;

  max-width: 750px;

  padding: 0rem 1rem;
}

.signup form .logo__app {
  width: 300px;

  margin: 0 auto;
  margin-bottom: 74px;
}

.signup form .row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
}

.signup form .input__box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;
}

.signup form .input__box input {
  outline: none;
  border: none;
  background: none;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  width: 90%;
}

.signup form .input__box input:-webkit-autofill,
.signup form .input__box input:-webkit-autofill:hover,
.signup form .input__box input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px var(--ultra-light-gray) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.signup form .buttons {
  display: flex;

  column-gap: 1rem;

  justify-content: space-between;

  margin-top: 60px;
}

.signup form .buttons a {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  text-decoration: none;
  column-gap: 28px;
  width: 100%;
}

.signup form .buttons a:hover {
  background-color: var(--ultra-light-gray);
  border-radius: 8px;
}

.forgot__password form .button__loading {
  cursor: not-allowed;
  pointer-events: none;
}

.signup form .buttons .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.signup form .buttons .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.signup form button {
  background-color: var(--green);
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;

  width: 100%;
  max-width: 308px;
  height: 65px;

  cursor: pointer;

  position: relative;
}

@media (max-width: 680px) {
  .signup form {
    align-items: center;

    padding: 0;

    max-width: 309px;
  }

  .signup form .row {
    justify-content: center;
    align-items: center;
  }

  .signup form .buttons {
    flex-wrap: wrap;

    margin-top: 1rem;
  }

  .signup form .buttons a {
    margin-bottom: 2rem;
  }

  .signup form .buttons button {
    max-width: none;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
