.dashboard {
  min-height: 100vh;
  width: 100%;

  background-color: var(--light-gray);
}

.dashboard .content {
  display: flex;
}

.dashboard .content .preloading {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;
}

.dashboard .content .container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 8rem 4rem 2rem 4rem;
}

.dashboard .content .container .welcome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin-bottom: 3.75rem;
}

.dashboard .content .container .welcome h4 {
  font-family: var(--font-regular);
  font-size: 1.75rem;
  font-weight: 300;
  color: #fff;
}

.dashboard .content .container .welcome h1 {
  font-family: var(--font-bold);
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
}

.dashboard .content .container .page__header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 4rem;
  margin-bottom: 5rem;
}

.dashboard .content .container .page__header .filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
}

.dashboard .content .container .page__header .filter .inputs {
  display: flex;
  column-gap: 1rem;
}

.dashboard .content .container .page__header .filter .inputs .filter__button {
  background-color: #4f8596;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  padding: 1.75rem;
  border-radius: 4px;
  cursor: pointer;
}

.dashboard .content .container .page__header .filter .selected__params {
  display: flex;
  flex-direction: column;
}

.dashboard .content .container .page__header .filter .selected__params h3 {
  font-family: var(--font-regular);
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
}

.dashboard .content .container .page__header .filter .selected__params h3 b {
  font-family: var(--font-bold);
  font-size: inherit;
  color: inherit;
}

.dashboard .content .container .page__header .warehouse__balance {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.dashboard .content .container .page__header .warehouse__balance .title__with__line {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.dashboard .content .container .page__header .warehouse__balance .title__with__line h5 {
  font-family: var(--font-regular);
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
  white-space: nowrap;
}

.dashboard .content .container .page__header .warehouse__balance .title__with__line hr {
  width: 100%;
  height: 1px;
}

.dashboard .content .container .page__header .warehouse__balance .box {
  background-color: var(--dark-gray);
  border: 1px solid var(--green);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  padding: 1rem;
}

.dashboard .content .container .page__header .warehouse__balance .box h1 {
  font-family: var(--font-bold);
  color: #fff;
  font-size: 1.5rem;
}

.dashboard .content .container .page__header .warehouse__balance .box .values {
  display: flex;
  justify-content: space-between;
}

.dashboard .content .container .page__header .warehouse__balance .box .values h3 {
  font-family: var(--font-regular);
  font-weight: 400;
  color: #fff;
  font-size: 1.25rem;
}

.dashboard .content .container .page__header .warehouse__balance .box .values h4 {
  font-family: var(--font-regular);
  font-weight: 400;
  color: #fff;
  font-size: 1.25rem;
}

.dashboard .content .container .section .title__with__line {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.dashboard .content .container .section .title__with__line h5 {
  font-family: var(--font-regular);
  font-style: italic;
  font-weight: 400;
  color: #fff;
  font-size: 2rem;
  white-space: nowrap;
  display: flex;
  column-gap: 1rem;
}

.dashboard .content .container .section .title__with__line hr {
  width: 100%;
  height: 1px;
}

.dashboard .content .container .section .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
}

.dashboard .content .container .section .last__classifications {
  width: 37rem;
  min-height: 250px;

  background-color: var(--dark-gray);
  border: 1px solid var(--green);
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  padding: 1rem;

  overflow-x: auto;
}

.dashboard .content .container .section .last__classifications .last__classifications__header {
  display: flex;
  justify-content: space-between;
}

.dashboard
  .content
  .container
  .section
  .last__classifications
  .last__classifications__header
  .last__classifications__header__buttons {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.dashboard .content .container .section .last__classifications .last__classifications__header h5 {
  font-family: var(--font-regular);
  font-weight: 400;
  color: #fff;
  font-size: 1.5rem;
}

.dashboard .content .container .section .last__classifications .last__classifications__header .details__button {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.dashboard .content .container .section .last__classifications .last__classifications__header .alert__icon {
  width: 44px;
  height: 44px;
}

.dashboard .content .container .section .last__classifications .last__classifications__body {
  width: 100%;
  display: flex;
  align-items: center;
}

.dashboard .content .container .section .last__classifications table {
  color: #fff;
  width: 100%;
}

.dashboard .content .container .section .last__classifications table tbody:before {
  padding-left: 1rem;
  line-height: 1rem;
  content: '.';
  color: #575656;
  display: block;
}

.dashboard .content .container .section .last__classifications table tbody tr td {
  padding: 0px 5px;
  white-space: nowrap;
}

.dashboard .content .container .section .last__classifications table thead tr th {
  font-family: var(--font-bold);
  padding: 0px 5px;
}
.dashboard .content .container .section .last__classifications table tbody tr td {
  font-family: var(--font-regular);
  font-weight: 400;
}

.dashboard .content .container .section .last__classifications .empty__data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard .content .container .section .last__classifications .empty__data h4 {
  font-family: var(--font-bold);
  color: #ccc;
  font-size: 1.125rem;
  text-align: center;
  max-width: 250px;
}

@media (max-width: 1345px) {
  .dashboard .content .container .stats {
    width: 250px;
    height: 150px;
  }

  .dashboard .content .container .section .last__classifications {
    width: 34rem;
  }
}

@media (max-width: 1265px) {
  .dashboard .content .container .stats {
    width: 220px;
    height: 150px;
  }

  .dashboard .content .container .stats .top__bar h4 {
    font-size: 20px;
  }

  .dashboard .content .container .section .last__classifications {
    width: 32rem;
  }
}

@media (max-width: 1145px) {
  .dashboard .content .container .stats {
    width: 190px;
    height: 150px;
  }

  .dashboard .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 1080px) {
  .dashboard .content .container .page__header {
    display: flex;
    flex-direction: column;
    column-gap: 4rem;
    margin-bottom: 5rem;
  }

  .dashboard .content .container .page__header .filter {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
    margin-bottom: 2rem;
  }

  .dashboard .content .container .page__header .filter .selected__params {
    text-align: right;
  }

  .dashboard .content .container .stats {
    width: 190px;
    height: 150px;
  }

  .dashboard .content .container .stats .top__bar h4 {
    font-size: 20px;
  }

  .dashboard .content .container .section .last__classifications {
    width: 100%;
  }

  .dashboard .content .container .section {
    width: 100%;
  }
}

@media (max-width: 945px) {
  .dashboard .content .container .stats {
    width: 180px;
    height: 150px;
  }

  .dashboard .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 950px) {
  .dashboard .content .container .table__box table thead tr td {
    font-size: 1rem;
  }

  .dashboard .content .container .table__box table tbody tr td {
    font-size: 1rem;
  }
}

@media (max-width: 865px) {
  .dashboard .content .container .info {
    column-gap: 1rem;

    justify-content: center;
  }
}

@media (max-width: 768px) {
  .dashboard .content .container .table__box .header {
    flex-wrap: wrap;

    justify-content: center;
  }

  .dashboard .content .container .table__box .header h1 {
    margin-bottom: 1rem;
    text-align: center;
  }

  .dashboard .content .container .page__header .filter {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
  }
}

@media (max-width: 550px) {
  .dashboard .content .container .page__header .filter .inputs {
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-content: right;
  }
}

@media (max-width: 425px) {
  .dashboard .content .container {
    padding: 8rem 1rem 4rem 1rem;
  }

  .dashboard .content .container .welcome h4 {
    font-size: 1.5rem;
  }

  .dashboard .content .container .welcome h1 {
    font-size: 2.25rem;
  }

  .dashboard .content .container .table__box {
    padding: 1.25rem;
  }
}
