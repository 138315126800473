.new__producer__modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;
}

.new__producer__modal__box {
  background-color: var(--dark-gray);
  padding: 2rem;
  width: 550px;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 20px;
}

.new__producer__modal__box form {
  margin: 40px 0 0 0;
}

.new__producer__modal__box form .input__box {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;

  margin: 10px 0;
}

.new__producer__modal__box form .input__box img {
  cursor: pointer;
}

.new__producer__modal__box form .input__box input {
  outline: none;
  border: none;
  background: none;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  padding: 4px;

  width: 90%;
}

.new__producer__modal__box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.new__producer__modal__box .header h1 {
  font-family: var(--font-bold);
  font-size: 32px;
  color: #fff;
}

.new__producer__modal__box .header img {
  cursor: pointer;
}

.new__producer__modal__box form .buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 1rem;

  padding-top: 40px;
}

.new__producer__modal__box form .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 65px;
  width: 260px;
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;
  cursor: pointer;

  position: relative;
}

.new__producer__modal__box form .buttons .confirm {
  background-color: var(--green);
}

.new__producer__modal__box form .buttons .cancel {
  background-color: var(--red);
}

.new__producer__modal__box form .buttons .button__loading {
  cursor: not-allowed;
  pointer-events: none;
}

.new__producer__modal__box form .buttons .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.new__producer__modal__box form .buttons .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
