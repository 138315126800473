.subheader {
  width: 100%;
  position: fixed;
  top: 75px;
  height: 50px;
  background-color: var(--dark-green);
  z-index: 996;
}

.subheader .alignment {
  max-width: 1360px;
  height: 100%;

  margin: 0 auto;

  display: flex;
  align-items: center;
  column-gap: 1rem;

  padding: 0 6rem;
}

.subheader .alignment img {
  width: 24px;
}

.subheader .alignment h1 {
  font-family: var(--font-italic);
  font-weight: 400;
  font-size: 1.25rem;
  color: #fff;
}
