.connected__devices__modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  padding: 0 2rem;
}

.connected__devices__box {
  background-color: var(--dark-gray);
  padding: 2.75rem;
  width: 880px;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 20px;

  position: relative;
}

.connected__devices__box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
}

.connected__devices__modal .connected__devices__box .header .liberated {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0.925rem;
}

.connected__devices__modal .connected__devices__box .header .liberated h5 {
  font-family: var(--font-regular);
  font-size: 32px;
  font-weight: 100;
  font-style: italic;
  color: #fff;
}

.connected__devices__box .header img {
  cursor: pointer;
}

.connected__devices__box .header h1 {
  font-family: var(--font-bold);
  font-size: 32px;
  color: #fff;
}

.connected__devices__box .header .input__box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;
}

.connected__devices__box .header img {
  margin-right: 1rem;
}

.connected__devices__box .header .input__box input {
  width: 100%;

  outline: none;
  border: none;
  background: none;

  font-family: var(--font-regular);
  font-size: 1.25rem;
  color: #fff;
}

.connected__devices__box .overflow {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.connected__devices__box table {
  width: 100%;
  border-collapse: collapse;
}

.connected__devices__box table thead {
  font-family: var(--font-bold);
  color: #fff;
}

.connected__devices__box table thead th {
  font-size: 24px;
  padding: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.connected__devices__box table thead td:nth-child(3) {
  text-align: right;
}

.connected__devices__box table tbody tr td {
  font-size: 24px;
  padding: 10px;
}

.connected__devices__box table tbody tr td:nth-child(3) {
  text-align: right;
}

.connected__devices__box table tbody tr:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.connected__devices__box table tbody {
  font-family: var(--font-regular);
  color: #fff;
}

.connected__devices__box table tbody:before {
  content: '@';
  display: block;
  line-height: 1rem;
  text-indent: -99999px;
}

@media (max-width: 945px) {
  .connected__devices__modal .connected__devices__box .header h1 {
    font-size: 1.5rem;
  }

  .connected__devices__modal .connected__devices__box table thead td,
  .connected__devices__modal .connected__devices__box table tbody tr td {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .connected__devices__modal .connected__devices__box {
    padding: 2rem;
  }
}

@media (max-width: 425px) {
  .connected__devices__modal .connected__devices__box {
    padding: 1rem;
  }

  .connected__devices__modal .connected__devices__box .header img {
    width: 38px;
  }

  .connected__devices__modal .connected__devices__box .header .liberated {
    display: block;
  }

  .connected__devices__modal .connected__devices__box .header .liberated h5 {
    font-size: 1.25rem;
    padding: 0 1rem 0 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 375px) {
  .connected__devices__modal .connected__devices__box .header h1 {
    font-size: 1rem;
    padding: 0 1rem 0 0;
  }

  .connected__devices__modal .connected__devices__box .header .liberated {
    display: block;
  }

  .connected__devices__modal .connected__devices__box .header .liberated h5 {
    font-size: 1rem;
    padding: 0 1rem 0 0;
    margin-bottom: 1rem;
  }
}
