.loading__box {
  position: relative;
  padding: 2rem 0 4rem 0;
}

.loading span {
  visibility: hidden;
  opacity: 0;
}

.loading:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.dryer {
  min-height: 100vh;
  width: 100%;

  background-color: var(--light-gray);
}

.dryer .content {
  display: flex;
}

.dryer .content .container {
  margin: 0 auto;
  padding: 12rem 4rem 2rem 4rem;
  max-width: 1380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3rem;
}

.dryer .content .container .stats__box {
  width: 100%;

  background-color: #575656;
  padding: 2.5rem;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  position: relative;
  overflow-x: auto;
}

.dryer .content .container .stats__box .alert__icon {
  position: absolute;
  width: 58px;
  top: 20px;
  right: 20px;
}

.dryer .content .container .stats__box .alert {
  font-family: var(--font-regular);
  font-weight: 400;
  font-size: 2rem;
  padding: 3rem 0 4rem 0;
  background-color: unset;
  text-align: center;
  color: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.dryer .content .container .stats__box .alert b {
  font-family: var(--font-bold);
  font-size: inherit;
}

.dryer .content .container .stats__box .info__box {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
}

.dryer .content .container .stats__box .info__box__header {
  width: 100%;
  display: flex;
  column-gap: 1rem;
}

.dryer .content .container .stats__box .info__box__header h1 {
  display: flex;
  font-family: var(--font-italic);
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

.dryer .content .container .stats__box .info__box__header h1.bold {
  font-weight: 700;
}

.dryer .content .container .stats__box .info__box .info__box__content {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
}

.dryer .content .container .stats__box .graphic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dryer .content .container table {
  width: 100%;

  font-family: var(--font-regular);

  color: #fff;

}

.dryer .content .container .table__footer {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dryer .content .container table tbody:before {
  padding-left: 1rem;
  line-height: 1rem;
  content: '.';
  color: #575656;
  display: block;
}

.dryer .content .container table tbody tr td {
  padding: 5px 0;
}

.dryer .content .container table thead tr th {
  font-family: var(--font-bold);
  padding: 0 0 0 10px;
}

.dryer .content .container table thead tr th:nth-child(1) {
  padding: 0px;
}

.dryer .content .container table thead tr th:nth-child(2) {
  padding: 0 10px;
}

.dryer .content .container .stats__box .info__box__header .filter__button {
  background-color: #4f8596;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .dryer .content .container {
    padding: 1.25rem;
  }
}
