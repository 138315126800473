* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;

  font-size: 16px;
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans Italic';
  src: url('./assets/fonts/IBMPlexSans-Italic.ttf');
}

@media (max-width: 1180px) {
  * {
    font-size: 14px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: 0 0 0px 1000px var(--ultra-light-gray) inset;
  transition: background-color 5000s ease-in-out 0s;
}

:root {
  --font-bold: 'IBM Plex Sans Bold';
  --font-regular: 'IBM Plex Sans';
  --font-italic: 'IBM Plex Sans Italic';

  --green: #449b67;
  --middle-green: #41895e;
  --dark-green: #347e52;
  --ultra-light-gray: #525252;
  --light-gray: #484848;
  --dark-gray: #424242;
  --red: #ef6053;
  --orange: #ffa800;
}
