.MuiSvgIcon-root {
  color: #a3a3a3;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.initial__classifications__filter__params__modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  padding: 1rem;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box {
  background-color: var(--dark-gray);
  padding: 2rem;
  width: 580px;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 20px;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .input__group {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box h3 {
  font-family: var(--font-bold);
  color: #fff;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .row {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .input__group label {
  font-family: var(--font-bold);
  font-size: 0.75rem;
  color: #a3a3a3;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box .header h1 {
  font-family: var(--font-bold);
  font-size: 32px;
  color: #fff;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box .header img {
  cursor: pointer;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 1rem;

  padding-top: 40px;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 65px;
  width: 260px;
  border-radius: 8px;

  font-family: var(--font-bold);
  font-size: 1.125rem;
  color: #fff;

  border: none;
  outline: none;
  cursor: pointer;

  position: relative;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons .confirm {
  background-color: var(--green);
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons .cancel {
  background-color: var(--red);
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons .button__loading {
  cursor: not-allowed;
  pointer-events: none;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons .button__loading span {
  visibility: hidden;
  opacity: 0;
}

.initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box form .buttons .button__loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@media (max-width: 375px) {
  .initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box {
    padding: 1rem;
  }

  .initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box .header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 320px) {
  .initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box {
    padding: 1rem;
  }

  .initial__classifications__filter__params__modal .initial__classifications__filter__params__modal__box .header h1 {
    font-size: 1.125rem;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
