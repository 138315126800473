.devices {
  min-height: 100vh;
  width: 100%;

  background-color: var(--light-gray);
}

.devices .content {
  display: flex;
}

.devices .content .container {
  width: auto;
  margin: 0 auto;
  padding: 12rem 4rem 2rem 4rem;
}

.devices .content .container .info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  row-gap: 1rem;
  column-gap: 2rem;

  margin-bottom: 3.5rem;
}

.devices .content .container .stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 16px 20px;
  border-radius: 8px;
  background-color: var(--green);

  width: 270px;
  height: 150px;
}

.devices .content .container .stats .top__bar {
  width: 100%;
}

.devices .content .container .stats .top__bar h4 {
  font-family: var(--font-regular);
  font-size: 26px;
  color: #fff;
  font-weight: 400;
}

.devices .content .container .stats .bottom__bar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.devices .content .container .stats .bottom__bar h2 {
  font-family: var(--font-bold);
  font-size: 2.125rem;
  color: #fff;
}

.devices .content .container .table__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-gray);
  padding: 2.75rem;
  width: 100%;
  border-radius: 8px;
}

.devices .content .container .table__box .empty__table {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 26px;
}

.devices .content .container .table__box .empty__table h5 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  max-width: 320px;
  color: #fff;
}

.devices .content .container .table__box .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
}

.devices .content .container .table__box .header h1 {
  font-family: var(--font-bold);
  font-size: 32px;
  color: #fff;
}

.devices .content .container .table__box .header .input__box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 21px;

  background-color: var(--ultra-light-gray);

  border-radius: 8px;
}

.devices .content .container .table__box .header img {
  margin-right: 1rem;
}

.devices .content .container .table__box .header .options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.devices .content .container .table__box .header .options button {
  border-radius: 8px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  min-width: 69px;
  min-height: 69px;
  cursor: pointer;
}

.devices .content .container .table__box .header .options button img {
  margin: 0;
}

.devices .content .container .table__box .header .input__box input {
  width: 100%;

  outline: none;
  border: none;
  background: none;

  font-family: var(--font-regular);
  font-size: 1.25rem;
  color: #fff;
}

.devices .content .container .table__box .overflow {
  overflow-x: auto;
  width: 100%;
}

.devices .content .container .table__box table {
  width: 100%;

  border-collapse: collapse;
}

.devices .content .container .table__box table thead {
  font-family: var(--font-bold);
  color: #fff;
}

.devices .content .container .table__box table thead td {
  font-size: 24px;
  padding: 0 10px;
}

.devices .content .container .table__box table thead td:nth-child(4) {
  text-align: right;
}

.devices .content .container .table__box table tbody tr td {
  font-size: 24px;
  padding: 10px;
}

.devices .content .container .table__box table tbody tr td:nth-child(4) {
  text-align: right;
}

.devices .content .container .table__box table tbody tr:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.devices .content .container .table__box table tbody {
  font-family: var(--font-regular);
  color: #fff;
}

.devices .content .container .table__box table tbody:before {
  content: '@';
  display: block;
  line-height: 1rem;
  text-indent: -99999px;
}

@media (max-width: 1345px) {
  .devices .content .container .stats {
    width: 250px;
    height: 150px;
  }
}

@media (max-width: 1265px) {
  .devices .content .container .stats {
    width: 220px;
    height: 150px;
  }

  .devices .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 1145px) {
  .devices .content .container .stats {
    width: 190px;
    height: 150px;
  }

  .devices .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 1025px) {
  .devices .content .container .stats {
    width: 190px;
    height: 150px;
  }

  .devices .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 945px) {
  .devices .content .container .stats {
    width: 180px;
    height: 150px;
  }

  .devices .content .container .stats .top__bar h4 {
    font-size: 20px;
  }
}

@media (max-width: 950px) {
  .devices .content .container .table__box table thead tr td {
    font-size: 1rem;
  }

  .devices .content .container .table__box table tbody tr td {
    font-size: 1rem;
  }
}

@media (max-width: 865px) {
  .devices .content .container .info {
    column-gap: 1rem;

    justify-content: center;
  }
}

@media (max-width: 700px) {
  .devices .content .container .table__box .header {
    flex-wrap: wrap;

    justify-content: center;
  }

  .devices .content .container .table__box .header h1 {
    margin-bottom: 1rem;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .devices .content .container {
    padding: 1.25rem;
  }

  .devices .content .container .welcome h4 {
    font-size: 1.5rem;
  }

  .devices .content .container .welcome h1 {
    font-size: 2.25rem;
  }

  .devices .content .container .table__box {
    padding: 1.25rem;
  }
}
