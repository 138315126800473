.toast {
  width: 100%;

  display: flex;
  justify-content: space-between;

  border-radius: 8px;
}

.error {
  background-color: var(--red);
}

.alert {
  background-color: var(--ultra-light-gray);
}

.success {
  background-color: var(--green);
}

.toast .icon {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 21px;

  border-right: 1px solid #747474;
}

.toast .icon img {
  width: 34px;
  height: 34px;
}

.toast .title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px;
  width: 100%;
}

.toast .title h5 {
  font-family: var(--font-bold);
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
}

@media (max-width: 680px) {
  .toast .title h5 {
    font-size: 0.85rem;
  }
}
