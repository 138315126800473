.numbers__box {
  width: 225px;
  height: 185px;
  background-color: var(--dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 35px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #818181;
}

.numbers__box h1 {
  font-family: var(--font-bold);
  font-size: 3rem;
  color: #fff;
}

.numbers__box .filter__button {
  background-color: #4f8596;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 8px;
  left: 8px;
  border-radius: 4px;
}

.numbers__box .right__icon__box {
  background-color: #4f8596;
  height: 100px;
  right: -50px;
  position: absolute;
  top: -50px;
  width: 100px;
  transform: rotate(-45deg);
}

.numbers__box .right__icon__box img {
  right: 68px;
  top: 38px;
  position: absolute;
  max-height: 24px;
  transform: rotate(45deg);
}

.numbers__box .stats__box__footer {
  background-color: #818181;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 4px 4px;
}

.numbers__box .stats__box__footer h3 {
  font-family: var(--font-bold);
  font-style: italic;
  color: #fff;
  font-size: 1.25rem;
}
